  @import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
  @import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

  /* Importing Bootstrap SCSS file. */
  @import '../node_modules/bootstrap/scss/bootstrap.scss';

  @import './styles/theme/main.scss';

  .modal-lg,
  .modal-xl {
    max-width: 620px !important;
  }

  .dropbtn {
    background-color: #4CAF50;
    color           : white;
    padding         : 0 10px;
    height          : 100%;
    font-size       : 16px;
    border          : none;
    cursor          : pointer;
    border-radius   : 5px;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display : inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    // transform       : translate(-100%, -30px);
    display         : none;
    position        : absolute;
    background-color: #f9f9f9;
    min-width       : 160px;
    width           : max-content;
    box-shadow      : 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index         : 500;
    border-radius   : 5px;

    & .option {
      color          : black;
      padding        : 12px 16px;
      text-decoration: none;
      display        : block;
      width          : 100%;
    }

    & .option:hover {
      background-color: #f1f1f1
    }
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }