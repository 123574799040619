@import './mixins';
@import './fonts';
// @import './reset';
@import './colors';
@import './vars';
@import './helpers';
@import './components/main.scss';

// Evita grid overflow breaks with paddings
html {
  box-sizing: border-box;
}

.cursor-pointer {
  cursor: pointer;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  padding   : 0;
  margin    : 0 !important;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-bottom: 0;
}

.card {
  border: 0;
}

ul.list-dots {
  list-style  : none;
  padding-left: 16px;

  &>li::before {
    content       : "\2022";
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color         : #de377c;
    /* Change the color */
    font-weight   : bold;
    /* If you want it to be bold */
    display       : inline-block;
    /* Needed to add space between the bullet and the text */
    width         : 1em;
    /* Also needed for space (tweak if needed) */
    margin-left   : -1em;
    /* Also needed for space (tweak if needed) */
  }
}