@use "sass:meta";

@mixin syntax-colors($args...) {
  @debug meta.keywords($args); //DEBUG MESSAGE

  @each $name,
  $color in meta.keywords($args) {
    .bg-#{$name} {
      background-color: $color !important;
    }

    .text-#{$name} {
      color: $color !important;
    }
  }
}
